<template>
  <div class="cooperation">
    <swiper :options="swiperOption" class="swiperBlock flexItem110">
      <swiper-item class="flexColStartRowCenter" v-for="(item,index) in banner" :key="index">
        <img :src="item.img" @click="bannerClick(item,index)">
      </swiper-item>
    </swiper>
    <img class="cooperation-image" src="../../assets/image/cooperation/cooperation_2.jpg">
    <img class="cooperation-image" src="../../assets/image/cooperation/cooperation_3.jpg">
    <img class="cooperation-image" src="../../assets/image/cooperation/cooperation_4.jpg">
  </div>
</template>

<script>
import ImageBox from "../../components/ImageBox/ImageBox";
import {swiper, swiperItem} from "../../components/swiper";
export default {
  name: "cooperation",
  components: {
    "swiper": swiper,
    "swiper-item": swiperItem,
    "image-box":ImageBox
  },
  data(){
    return {
      swiperOption: {
        height:"auto",
        width:"100%",
        pageBtn: false,
        callback: () => {
        },
        pagination: {
          show: false,
          canClick:false
        },
        current: 0,
        itemsNum: 1,
        loop: false,
        loopTime: 5000,
        auto: false
      },
      banner: [
        {
          img: require("../../assets/image/cooperation/cooperation_1.jpg"),
          type:"none"
        }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
  @import "cooperation_pc";
}

@media screen and (max-width: $minDeviceWidth_pc) {
  @import "cooperation_mobile";
}
</style>